<script setup>
import alzaLogo from '@/app/assets/logos/logo_alza.png'
import { useRoute, useRouter } from 'vue-router/composables'
import { loginRoutes } from '@/app/config/customLogin'

const route = useRoute()
const router = useRouter()

function redirectToLogin() {
  if (!loginRoutes.includes(route.name)) {
    router.push({ name: 'alza-login' })
  }
}
</script>

<template>
  <v-app class="access-background">
    <v-app-bar flat app class="access-background">
      <div class="d-flex align-center mx-auto">
        <v-img
          :src="alzaLogo"
          transition="scale-transition"
          class="shrink"
          alt="Alza Logo"
          width="7.5rem"
          contain
          style="cursor: pointer"
          @click="redirectToLogin"
        />
      </div>
    </v-app-bar>
    <v-main>
      <v-container class="fill-height">
        <slot></slot>
      </v-container>
    </v-main>
  </v-app>
</template>

<style lang="scss" scoped>
.access-background {
  background: url('~@/app/assets/images/unauthorized_background.png') no-repeat fixed center;
  background-size: cover;
}
</style>
